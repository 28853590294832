








































































































































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, ref, computed } from "@vue/composition-api";
import {
  AtomIcon,
  AtomText,
  AtomTextTagEnum,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomButtonTypeEnum,
  AtomButtonSizeEnum,
  AtomButtonLink,
  AtomButtonLinkTypeEnum,
  AtomButtonLinkSizeEnum,
  AtomMoney,
} from "@/v2/new-design-system";
import { TabsOrderedBy } from "../ordered-by";
import { IFetchTabResponse } from "@/v2/repo/tabs/fetch-tab";
import { phoneBeautifier } from "@chatfood/core-utils";
import { t } from "@/i18n";
import { VoidItems } from "../void-items";
import { Currency } from "dinero.js";
const css = bemBuilder("tabs-ordered-items");

type IPrice = {
  value: number;
  currency: Currency;
};

export default defineComponent({
  name: "TabsOrderedItems",
  components: {
    AtomIcon,
    AtomText,
    VoidItems,
    AtomMoney,
    AtomButtonLink,
    TabsOrderedBy,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    tabId: {
      type: String,
      required: true,
    },
    items: {
      type: Array as PropType<IFetchTabResponse["lineItems"]>,
      default: () => [],
    },
    itemsOwner: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },
    outstandingBalance: {
      type: Object as PropType<IFetchTabResponse["outstandingBalance"]>,
      required: true,
    },
    isTabOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    moveItem: (item: IFetchTabResponse["lineItems"][0]) => true,
  },
  setup(props, { emit }) {
    const itemToBeVoided = ref<{
      itemId: string;
      quantity: number;
      unitPrice: IPrice;
    }>();

    const itemsAvailables = computed(() => {
      const statesNotToBeDisplayed: IFetchTabResponse["lineItems"][0]["state"][] =
        ["MOVED"];

      return props.items.filter(
        (item) => !statesNotToBeDisplayed.includes(item.state)
      );
    });

    function onVoidItem(itemId: string, quantity: number, unitPrice: IPrice) {
      itemToBeVoided.value = {
        itemId,
        quantity,
        unitPrice,
      };
    }

    function isVoidedState(state: IFetchTabResponse["lineItems"][0]["state"]) {
      return state === "VOIDED";
    }

    function onMoveItem(item: IFetchTabResponse["lineItems"][0]) {
      emit("moveItem", item);
    }

    function getOrderedByLabel(itemId: string, type: "customer" | "staff") {
      return type === "customer"
        ? props.itemsOwner[itemId]
        : t("module.tabs.ordered_items.staff");
    }

    function getOrderedByTooltipLabel(
      type: "customer" | "staff",
      staffName = "",
      customerPhone = ""
    ) {
      return type === "staff" ? staffName : phoneBeautifier(customerPhone);
    }

    return {
      t,
      css,
      onMoveItem,
      itemsAvailables,
      getOrderedByLabel,
      getOrderedByTooltipLabel,
      AtomTextTagEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonTypeEnum,
      AtomButtonSizeEnum,
      AtomButtonLinkTypeEnum,
      AtomButtonLinkSizeEnum,
      onVoidItem,
      itemToBeVoided,
      isVoidedState,
    };
  },
});
